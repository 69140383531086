/* eslint-disable jsx-a11y/media-has-caption */
import { styled } from 'twin.macro';
import React from 'react';

import { Link } from 'gatsby';
import Seo from '../components/Seo';
import Layout from '../components/Layout';
import config from '../utils/config';
import Header from '../components/Header';

const Container = styled.div`
  .wrapper {
    min-height: 85vh;
  }
`;

const ThanksPage = () => (
  <Layout hideHeader>
    <Seo
      title="Thanks"
      description={config.description}
      url={config.siteUrl}
      image={config.logo}
    />
    <Container>
      <Header />

      <div
        className="wrapper"
        tw=" flex items-end sm:items-center justify-center p-4 text-center sm:p-0"
      >
        <div tw="border-gray-200 border relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
          <div>
            <div tw="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <svg
                tw="h-6 w-6 text-green-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <div tw="mt-3 text-center sm:mt-5">
              <h3
                tw="text-lg leading-6 font-medium text-gray-900"
                id="modal-title"
              >
                Thank You
              </h3>
              <div tw="mt-2">
                <p tw="text-sm text-gray-500">
                  Your submission has been received. We will be in touch and
                  contact you soon.
                </p>
              </div>
            </div>
          </div>
          <div tw="mt-5 sm:mt-6">
            <Link to="/">
              <button
                type="button"
                tw="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              >
                Go back to Site
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Container>
  </Layout>
);
export default ThanksPage;
